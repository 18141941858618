export const ROUTE_TOKENS = {
  ARCHIVE: {
    ARCHIVE: 'archive',
  },
  DISPOSABLE_LOGIN: 'disposable-login',
  EXHIBITION: {
    DOCUMENTS: 'documents',
    PREPARATION: 'preparation',
    SETTINGS: 'settings',
    STAGES: 'stages',
  },
  EXHIBITIONS: 'exhibitions',
  FAQ: 'faq',
  LOGIN: 'login',
  NOTIFICATION: 'notification',
  PARTICIPATION: {
    PARTICIPATION: 'effective-participation',
    REQUIREMENTS: 'requirements',
    SCHEDULE: 'schedule',
  },
  PASSWORD_RECOVERY: 'password-recovery',
  PROFILE: {
    NOTIFICATIONS: 'notifications',
    PROFILE: 'profile',
    SETTINGS: 'settings',
  },
  REGISTRATION: 'registration',
  REGISTRATION_SUCCESS: 'registration-success',
  RESTORE_PASSWORD: 'restore_password',
  RESTORE_PASSWORD_SUCCESS: 'restore_password_success',
  SETTINGS: {
    BADGES: 'badges',
    BRANDS: 'brands',
    PINS: 'pins',
    PROMOCODES: 'promocodes',
    STANDS: 'stands',
  },
} as const;
