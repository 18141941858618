import { InjectionToken } from '@angular/core';

import { ExpocabConfig } from './model';

export const EXPOCAB_DI_CONFIG: ExpocabConfig = {
  production: false,
};

export const EXPOCAB_CONFIG = new InjectionToken<ExpocabConfig>(
  'expocab.config',
);
