export const phoneCountries = [
  { code: 'ru', name: 'Россия', phoneCode: '+7' },
  { code: 'by', name: 'Беларусь', phoneCode: '+375' },
  { code: 'kz', name: 'Казахстан', phoneCode: '+7' },
  { code: 'az', name: 'Азербайджан', phoneCode: '+994' },
  { code: 'am', name: 'Армения', phoneCode: '+374' },
  { code: 'kg', name: 'Кыргызстан', phoneCode: '+996' },
  { code: 'md', name: 'Молдова', phoneCode: '+373' },
  { code: 'tj', name: 'Таджикистан', phoneCode: '+992' },
  { code: 'tm', name: 'Туркменистан', phoneCode: '+993' },
  { code: 'uz', name: 'Узбекистан', phoneCode: '+998' },
  { code: 'ua', name: 'Украина', phoneCode: '+380' },
] as const;
